export const UserData = [
    {
      id: 1,
      year: 2016,
      userGain: 60,
      userLost: 40,
    },
    {
      id: 2,
      year: 2017,
      userGain: 50,
      userLost: 80,
    },
    {
      id: 3,
      year: 2018,
      userGain: 40,
      userLost: 20,
    },
    {
      id: 4,
      year: 2019,
      userGain: 90,
      userLost: 80,
    },
    {
      id: 5,
      year: 2020,
      userGain: 40,
      userLost: 50,
    },
    {
      id: 6,
      year: 2021,
      userGain: 90,
      userLost: 80,
    },
    {
      id: 2,
      year: 2017,
      userGain: 40,
      userLost: 50,
    },
    
  ];