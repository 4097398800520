import { Link } from "react-router-dom";
import "../../Styles/Ifooter.css";
import newlogo from "../../Assets/images/newlogo.png";
import facebooklogo from "../../Assets/images/facebookvectoricon.png";
import twitterimg from "../../Assets/images/twitterimg.png";

const Ifooter = () => {
  return (
    <div className="Ifooter_main_div">
      <div className="Ifooter_main_upperdiv">
        <div className="Ifooter_main_first_section">
          <Link className="Ifooter_main_first_section_link">HOME</Link>
          <div className="Ifooter_main_first_section_link_line"></div>
          <div className="Ifooter_main_first_section_logo_div">
            <img src={newlogo} alt="newlogo" />
          </div>
          <p className="Ifooter_main_first_section_logo_div_p">
            We Devise and Develop AI Workforce customised to your Business.
          </p>
          <div className="Ifooter_main_first_section_socialdiv">
            <Link
              target="_blank"
              to="https://www.linkedin.com/company/altusintel/"
              className="Ifooter_main_first_section_socialdiv_Icon"
            >
              <i class="bi bi-linkedin"></i>
            </Link>
            {/* <Link
              target="_blank"
              to="https://www.linkedin.com/company/altusintel/"
              className="Ifooter_main_first_section_socialdiv_Icon_img"
            >
              <img width={16} src={facebooklogo} alt="facebooklogo" />
            </Link> */}
            <Link
              target="_blank"
              to="https://x.com/altusintel"
              className="Ifooter_main_first_section_socialdiv_Icon_img"
            >
              <img src={twitterimg} alt="twitterimg" />
            </Link>
            <Link
              target="_blank"
              to="https://www.instagram.com/altus_intel?igsh=NXo0NDN4YnIyOWtm&utm_source=qr"
              className="Ifooter_main_first_section_socialdiv_Icon"
            >
              <i class="bi bi-instagram"></i>
            </Link>
          </div>
        </div>
        {/* -*----------------------------------------------------- */}
        <div className="Ifooter_main_second_section">
          <Link className="Ifooter_main_second_section_link">
            Company Links
          </Link>
          <div className="Ifooter_main_second_section_link_line"></div>
          <div className="Ifooter_main_second_section_linkdiv">
            <Link to="/" className="Ifooter_main_second_section_linkdiv_link">
              Home
            </Link>
            <Link
              to="/About"
              className="Ifooter_main_second_section_linkdiv_link"
            >
              About Us
            </Link>
            <Link
              to="/aicustomerservice"
              className="Ifooter_main_second_section_linkdiv_link"
            >
              AI Workforce Frontend
            </Link>
            <Link
              to="/AIcustomworkforce"
              className="Ifooter_main_second_section_linkdiv_link"
            >
              AI Workforce Backend
            </Link>
            <Link
              to="/Contact"
              className="Ifooter_main_second_section_linkdiv_link"
            >
              Contact Us
            </Link>
          </div>
        </div>
        {/* -*------------------------------------------------------ */}
        <div className="Ifooter_main_third_section">
          <Link className="Ifooter_main_second_section_link">Contact Info</Link>
          <div className="Ifooter_main_second_section_link_line"></div>
          {/* <div className="footer_divided_section">
            <Link
              to="#"
              className="footer_divided_section_LINK"
              onClick={(e) => {
                window.location.href = "mailto:inquire@altus-intel.com";
                e.preventDefault();
              }}
            >
              inquire@altus-intel.com
            </Link>
          </div> */}
          <div className="footer_divided_section">
            <Link
              to="#"
              className="footer_divided_section_LINK"
              onClick={(e) => {
                window.open("mailto:inquire@altus-intel.com", "_blank");
                e.preventDefault();
              }}
            >
              inquire@altus-intel.com
            </Link>
          </div>
        </div>
      </div>
      <div className="Ifooter_main_lowerdiv">
        <p>
          © 2023 - 2024 ALTUS AI AUTOMATION AND CONSULTING. All Rights Reserved.
        </p>
        <p>Terms & Conditions</p>
      </div>
    </div>
  );
};

export default Ifooter;
